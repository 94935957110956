export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Gestión Empresa',
        route: 'empresaVer',
        icon: 'HomeIcon',
        resource: 'empresaVer',
        action: 'empresaVer',
      },
      {
        title: 'Cargas',
        route: 'cargasVer',
        icon: 'UploadCloudIcon',
        resource: 'cargasVer',
        action: 'cargasVer',
      },
      {
        title: 'Roles',
        route: 'rolVer',
        icon: 'HelpCircleIcon',
        resource: 'rolVer',
        action: 'rolVer',
      },
      {
        title: 'Permisos',
        route: 'permisoVer',
        icon: 'HelpCircleIcon',
        resource: 'permisoVer',
        action: 'permisoVer',
      },
      {
        title: 'Notificaciones',
        route: 'notificacionVer',
        icon: 'CalendarIcon',
        resource: 'notificacionVer',
        action: 'notificacionVer',
      },
      {
        title: 'Tienda',
        route: 'tiendaVer',
        icon: 'UploadCloudIcon',
        resource: 'tiendaVer',
        action: 'tiendaVer',
      },
      {
        title: 'Trivia',
        route: 'triviaVer',
        icon: 'UploadCloudIcon',
        resource: 'triviaVer',
        action: 'triviaVer',
      },
      {
        title: 'Canje cupon',
        route: 'cuponVer',
        icon: 'InboxIcon',
        resource: 'cuponVer',
        action: 'cuponVer',
      },
      {
        title: 'Dashboard',
        route: 'dashboardVer',
        icon: 'BarChartIcon',
        resource: 'dashboardVer',
        action: 'dashboardVer',
      },
      {
        title: 'Reportes',
        route: 'reporteVer',
        icon: 'BarChartIcon',
        resource: 'reporteVer',
        action: 'reporteVer',
      },
    ],
  },
]
